import Dropzone from 'react-dropzone';
import { useUploadDocMutation } from '../../../app/api/orderApiSlice';

const OrderFileDownloader = (props) => {
    const { infoText, fileType, orderId, accept } = props;

    const [uploadFile] = useUploadDocMutation();

    const downloadFile = (acceptedFiles) => {
        var data = new FormData();
        acceptedFiles.forEach((file) => {
            data.append('formFile', file);
        });
        uploadFile({
            orderId,
            typeId: fileType,
            formFile: data,
        });
    };

    return (
        <Dropzone onDrop={(acceptedFiles) => downloadFile(acceptedFiles)} accept={accept}>
            {({ getRootProps, getInputProps }) => (
                <section>
                    <div {...getRootProps()} className='accordion-dropzone mb-4'>
                        <input {...getInputProps()} />
                        <div className='text-center text-about-file mb-3'>{infoText}</div>
                        <div className='text-center text-download'>
                            <img src='/images/icons/attach.svg' height={16} className='me-2 align-top' />
                            Загрузить
                        </div>
                    </div>
                </section>
            )}
        </Dropzone>
    );
};

export default OrderFileDownloader;
